import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import OneSignal from 'onesignal-cordova-plugin';
import { BehaviorSubject, tap } from 'rxjs';
import { BaseService } from './base.service';
import { UserTransformer } from '../utils/transformers/user.transformer';
import * as ApiLogin from '../interfaces/api/login';
import * as ApiForgotPassword from '../interfaces/api/forgot-password';
import * as ApiRefreshToken from '../interfaces/api/refresh-token';
import * as ApiRegister from '../interfaces/api/register';
import * as ApiUpdatePassword from '../interfaces/api/update-password';
import * as ApiChangeProfile from '../interfaces/api/change-profile';
import * as ApiUser from '../interfaces/api/user';
import * as ApiSign from '../interfaces/api/signature';
import { User } from '../interfaces/user';
import { Capacitor } from '@capacitor/core';

const ACCESS_TOKEN_KEY = 'accessToken';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private _user = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient, private platform: Platform, private userTransformer: UserTransformer) {
    super();
  }

  get user() {
    return this._user.asObservable();
  }

  async isAuthenticated() {
    const accessToken = await this.getAccessToken();

    return !!accessToken;
  }

  async setAccessToken(accessToken: string) {
    await Preferences.set({
      key: ACCESS_TOKEN_KEY,
      value: accessToken,
    });
  }

  async removeAccessToken() {
    await Preferences.remove({
      key: ACCESS_TOKEN_KEY,
    });
  }

  async getAccessToken(): Promise<string | null> {
    const { value } = await Preferences.get({
      key: ACCESS_TOKEN_KEY,
    });

    return value || null;
  }

  setUser(user: User | null) {
    this._user.next(user);
  }

  unsetUser() {
    this.setUser(null);
  }

  register(payload: ApiRegister.RequestBody) {
    return this.http.post<ApiRegister.ResponseBody>(`${this.apiUrl}/users/register`, payload).pipe(
      tap(async response => {
        await this.setAccessToken(response.access_token);

        this.setUser(this.userTransformer.transform(response.user));

        if (Capacitor.isNativePlatform()) {
          this.registerOneSignalUserId();
        }
      }),
    );
  }

  registerEnterprise(payload: ApiRegister.RequestBodyEnterprise) {
    return this.http.post<ApiRegister.ResponseBody>(`${this.apiUrl}/enterprise/register`, payload).pipe(
      tap(async response => {
        // await this.setAccessToken(response.access_token);

        // this.setUser(this.userTransformer.transform(response.user));

        if (Capacitor.isNativePlatform()) {
          this.registerOneSignalUserId();
        }
      }),
    );
  }

  login(payload: ApiLogin.RequestBody) {
    return this.http.post<ApiLogin.ResponseBody>(`${this.apiUrl}/auth/login`, payload).pipe(
      tap(async response => {
        await this.setAccessToken(response.access_token);

        this.setUser(this.userTransformer.transform(response.user));

        if (Capacitor.isNativePlatform()) {
          this.registerOneSignalUserId();
        }
      }),
    );
  }

  forgotPassword(payload: ApiForgotPassword.RequestBody) {
    return this.http.post<ApiForgotPassword.ResponseBody>(`${this.apiUrl}/auth/forgot_password`, payload);
  }

  getUser() {
    return this.http.get<ApiUser.ResponseBody>(`${this.apiUrl}/users/profile`).pipe(
      tap(async response => {
        this.setUser(this.userTransformer.transform(response));
      }),
    );
  }

  getUserBalance() {
    return this.http.get<ApiUser.ResponseBody>(`${this.apiUrl}/users/profile`);
  }

  logout() {
    // return this.http.post(`${this.apiUrl}/logout`, null).pipe(
    //   tap(async () => {
    //     await this.removeAccessToken();

    //     this.unsetUser();
    //   }),
    // );
    this.removeAccessToken();
    this.unsetUser();
  }

  refreshToken() {
    return this.http.post<ApiRefreshToken.ResponseBody>(`${this.apiUrl}/refresh`, null).pipe(
      tap(async response => {
        await this.setAccessToken(response.access_token);
      }),
    );
  }

  updateProfile(payload: ApiUser.RequestBody) {
    return this.http.post<ApiUser.ResponseBody>(`${this.apiUrl}/update-profile`, payload).pipe(
      tap(response => {
        this.setUser(this.userTransformer.transform(response));
      }),
    );
  }

  updatePassword(payload: ApiUpdatePassword.RequestBody) {
    return this.http.post(`${this.apiUrl}/users/change_password`, payload);
  }

  changeProfile(payload: ApiChangeProfile.RequestBody) {
    return this.http.post<ApiChangeProfile.ResponseBody>(`${this.apiUrl}/users/change_profile`, payload).pipe(
      tap(async response => {
        this.setUser(this.userTransformer.transform(response));
      }),
    );
  }

  changeProfilePicture(payload: any) {
    return this.http.post<ApiChangeProfile.ResponseBody>(`${this.apiUrl}/users/profile_picture`, payload).pipe(
      tap(async response => {
        this.setUser(this.userTransformer.transform(response));
      }),
    );
  }

  private registerOneSignalUserId() {
    let platform = "";
    if(this.platform.is('android')){
      platform = "ANDROID";
    }else if (this.platform.is('ios')) {
      platform = "IOS";
    }

    OneSignal.getDeviceState(({ userId }) =>
      this.http
        .post(`${this.apiUrl}/users/onesignal/register`, {
          onesignal_id: userId,
          platform: platform
        })
        .subscribe(),
    );
  }

  updateSign(payload: ApiSign.RequestBody) {
    return this.http.post<ApiUser.ResponseBody>(`${this.apiUrl}/users/change_signature`, payload).pipe(
      tap(async response => {
        this.setUser(this.userTransformer.transform(response));
      }),
    );
  }

  checkEmail(payload: any) {
    return this.http.post(`${this.apiUrl}/users/check_email`, payload);
  }

  checkAccount(payload: any) {
    return this.http.post(`${this.apiUrl}/users/check_account`, payload);
  }

  registerMember(payload: ApiRegister.RequestBodyMember) {
    return this.http.post(`${this.apiUrl}/enterprise/invite`, payload);
  }

  getAllMember() {
    return this.http.get(`${this.apiUrl}/enterprise/members`);
  }

  revokeMember(param: any) {
    return this.http.delete(`${this.apiUrl}/enterprise/revoke/${param}`);
  }

  registerEsign() {
    return this.http.post(`${this.apiUrl}/esign_register`, {});
  }

  resendRegisterEsign() {
    return this.http.post(`${this.apiUrl}/esign_register/resend_activation`, {});
  }

  activeEmailEsign(payload: any) {
    return this.http.post(`${this.apiUrl}/esign_register/active_account`, payload);
  }

  otpBeforeKYC() {
    return this.http.post(`${this.apiUrl}/esign_register/send_otp`, {
      no_loading: true,
    });
  }

  registerKYC(payload: any) {
    return this.http.post(`${this.apiUrl}/esign_register/kyc`, payload);
  }

  deleteAccount(){
    return this.http.post(`${this.apiUrl}/auth/delete_account`, null);
  }
}
